import React from "react";
import * as Icon from "react-feather";
import styled from "styled-components";
import theme from "../../theme/theme.json";

const Carousel = ({ id, children }) => {
  const carouselId = id;
  function scrollToNext() {
    console.log(
      `Srcoll left is: ${document.getElementById(`${carouselId}`).scrollLeft}`
    );
    console.log(document.getElementById(`${carouselId}`).scrollWidth);
    if (
      document.getElementById(`${carouselId}`).scrollLeft <
      document.getElementById(`${carouselId}`).scrollWidth - 1058
    )
      document.getElementById(`${carouselId}`).scrollBy({
        left: document.getElementById(`${carouselId}`).firstChild.clientWidth,
        top: 0,
        behavior: "smooth",
      });
    else
      document
        .getElementById(`${carouselId}`)
        .scrollTo({ left: 0, top: 0, behavior: "smooth" });
  }
  function scrollToPrev() {
    if (document.getElementById(`${carouselId}`).scrollLeft != 0)
      document.getElementById(`${carouselId}`).scrollBy({
        left: -document.getElementById(`${carouselId}`).firstChild.clientWidth,
        top: 0,
        behavior: "smooth",
      });
    else
      document.getElementById(`${carouselId}`).scrollTo({
        left: document.getElementById(`${carouselId}`).scrollWidth,
        top: 0,
        behavior: "smooth",
      });
  }

  return (
    <CarouselContainer>
      <ButtonContainer>
        <StyledButton aria-label="carousel scroll left" onClick={scrollToPrev}>
          <Icon.ChevronLeft color="#fff" />
        </StyledButton>
        <h1>Recent News</h1>
        <StyledButton aria-label="carousel scroll right" onClick={scrollToNext}>
          <Icon.ChevronRight color="#fff" />
        </StyledButton>
      </ButtonContainer>
      <CarouselScroller id={carouselId}>{children}</CarouselScroller>
    </CarouselContainer>
  );
};

export default Carousel;

const CarouselContainer = styled.div``;

const CarouselScroller = styled.div`
  margin-top: 0;
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  & > div {
    margin-left: 15px;
    scroll-snap-align: center;
    min-width: 24%;
    min-height: 200px;
    scroll-snap-align: start;
  }
  & > div:first-of-type {
    margin-left: 0;
  }
  & ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1024px) {
    & > div {
      min-width: 48%;
    }
  }
  @media (max-width: 768px) {
    & > div {
      min-width: 100%;
    }
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 30px;
`;

const StyledButton = styled.button`
  padding: 0;
`;
