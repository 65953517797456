import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Researchers = ({ children }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "adriftabout.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = placeholderImage.childImageSharp.gatsbyImageData;
  const pluginImage = getImage(image);

  return (
    <BgImage
      tag="div"
      image={pluginImage}
      style={{ backgroundPosition: "50% 15%" }}
    >
      {children}
    </BgImage>
  );
};

export default Researchers;
