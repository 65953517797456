import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import Carousel from "../components/site/HomeCarousel";
import theme from "../theme/theme.json";
import Button from "../components/Button";
import HomeBanner from "../components/homeBlocks/HomeBanner";
import About from "../components/homeBlocks/About";
import Researchers from "../components/homeBlocks/Researchers";
import Publications from "../components/homeBlocks/Publications";
import News from "../components/homeBlocks/News";
import Alumni from "../components/homeBlocks/Alumni";
import Join from "../components/homeBlocks/Join";
import PeopleCount from "../components/site/PeopleCount";
import PublicationsCount from "../components/site/PublicationsCount";

const getData = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "news" } } }
      limit: 8
    ) {
      edges {
        node {
          fields {
            slug
            collection
          }
          excerpt(pruneLength: 140)
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            alt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
          }
        }
      }
    }
  }
`;

const Index = () => {
  const data = useStaticQuery(getData);
  const posts = data.allMarkdownRemark.edges;

  return (
    <>
      <SEO
        title="Marine Plastic & Seabird Research"
        description="Adrift Lab is a research group focused on seabirds and marine plastics research. Our mission is to use insights gathered from our long-term research to inform marine and waste policy, and to drive positive changes at the individual and community level, for the benefit of our oceans."
      />
      <Layout>
        <HomeBanner>
          <TitleContainer>
            <BannerContent width="750px">
              <BannerHeading>We study all things Adrift</BannerHeading>
              <Button
                col="white"
                textCol="black"
                hoverCol={theme.col.bluegrey[100]}
                link="true"
                to="#content"
              >
                Learn More
              </Button>
            </BannerContent>
          </TitleContainer>
          <CountPlacer>
            <PublicationsCount />
            <PeopleCount />
          </CountPlacer>
        </HomeBanner>

        <section id="content">
          <Container width="1024px" mt="80px">
            <Owl>
              <h2>What We Do</h2>

              <p>
                Adrift Lab is a research group focused on seabirds and marine
                plastics research. Our mission is to use insights gathered
                from our long-term research to inform marine and waste policy,
                and to drive positive changes at the individual and community
                level, for the benefit of our oceans.
              </p>
            </Owl>
            <HomeGrid>
              <Link to="/about">
                <About>
                  <BlockTitle>Our Mission</BlockTitle>
                </About>
              </Link>
              <Link to="/core-team">
                <Researchers>
                  <BlockTitle>Researchers</BlockTitle>
                </Researchers>
              </Link>
              <Link to="/publications">
                <Publications>
                  <BlockTitle>Publications</BlockTitle>
                </Publications>
              </Link>
              <Link to="/news">
                <News>
                  <BlockTitle>News</BlockTitle>
                </News>
              </Link>
              <Link to="/alumni">
                <Alumni>
                  <BlockTitle>Alumni</BlockTitle>
                </Alumni>
              </Link>
              <Link to="/join-adrift-lab">
                <Join>
                  <BlockTitle>Join Us</BlockTitle>
                </Join>
              </Link>
            </HomeGrid>
          </Container>
        </section>

        <BlueSection>
          <Container width="1024px" mt="80px" mb="80px" padding="80px">
            <Carousel id="homeCarousel">
              {posts.map(({ node }) => (
                <CarouselCard key={node.id}>
                  <Owl margin="1rem">
                    <PostLink
                      to={`/${node.fields.collection}${node.fields.slug}`}
                    >
                      <GatsbyImage
                        image={
                          node.frontmatter.image.childImageSharp.gatsbyImageData
                        }
                      />
                      <PostTitle>{node.frontmatter.title}</PostTitle>
                    </PostLink>
                    <p>{node.excerpt}</p>
                    <Button
                      col="white"
                      textCol="black"
                      hoverCol={theme.col.bluegrey[100]}
                      link="true"
                      to={`/news${node.fields.slug}`}
                    >
                      Full Article »
                    </Button>
                  </Owl>
                </CarouselCard>
              ))}
            </Carousel>
          </Container>
        </BlueSection>
        <section>
          <Container mt="80px" mb="80px" width="1024px">
            <StyledFigure>
              <blockquote>
                "Our goal ultimately is to try and understand the impacts of
                plastic pollution on different parts of marine ecosystems"
              </blockquote>
              <figcaption>- Dr Alex Bond</figcaption>
            </StyledFigure>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default Index;
const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  & ::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BannerContent = styled.div`
  text-align: center;
  padding: 20px;
  width: 90vw;
  max-width: 944px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  bottom: 6%;
  z-index: 4;
  @media (max-width: 600px) {
    & h1 {
      font-size: 36px;
    }
  }
  & a {
    margin: 10px auto 0;
    border-radius: 8px;
  }
`;

const BannerHeading = styled.h1`
  font-size: 48px;
  line-height: 1.2em;
  color: white;
  text-align: center;
`;

const CountPlacer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  padding: 5vw;
  height: 100%;
  align-items: flex-end;
  max-width: 1024px;
  margin: auto;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const BlueSection = styled.section`
  background-color: ${theme.col.primary.low};
  color: white;
`;

const CarouselCard = styled.div``;

const StyledFigure = styled.figure`
  text-align: center;
  width: 100%;
  padding: none;
  border: none;
  & blockquote {
    font-size: 30px;
    line-height: 1.5;
  }
  & figcaption {
    font-size: 1rem;
  }
`;

const HomeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 1.5rem;
  & div {
    height: 180px;
    position: relative;
  }
  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    & a:first-of-type,
    a:last-of-type {
      grid-column: span 2;
    }
  }
  @media (min-width: 768px) {
    & div {
      height: 250px;
    }
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
    & div,
    a:last-of-type {
      grid-column: span 1;
    }
    & a:first-of-type,
    a:nth-of-type(4) {
      grid-column: span 2;
    }
  }
`;

const BlockTitle = styled.span`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(11, 23, 40, 0.7);
  color: white;
  padding: 0 20px;
  font-weight: bold;
  height: 45px !important;
  display: flex;
  align-items: center;
`;

const PostLink = styled((props) => <Link {...props} />)`
  color: white;
  text-decoration: none;
`;

const PostTitle = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  display: block;
`;
