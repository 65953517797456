import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Users } from "react-feather";
import styled from "styled-components";
import theme from "../../theme/theme.json";

const getData = graphql`
  {
    allMarkdownRemark(filter: { fields: { collection: { eq: "team" } } }) {
      totalCount
    }
  }
`;

const PeopleCount = () => {
  const data = useStaticQuery(getData);
  const count = data.allMarkdownRemark.totalCount;
  return (
    <CountContainer>
      <Users size={40} />
      <p>From {count} people</p>
    </CountContainer>
  );
};

export default PeopleCount;

const CountContainer = styled.div`
  background: white;
  color: black;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  padding: 14px;
  border-radius: 10px;
  font-weight: bold;
  font-size: ${theme.fs.xs};
  & svg {
    width: 48px;
    margin: auto;
  }
`;
